<template>
    <div
        class="popup-wrap"
        @click="$store.commit('popups/hide', 'recipients')"
    >
        <form
            class="c-recipients-popup"
            @click.stop
            @submit.prevent="handleSubmit"
        >
            <div class="head">
                <div></div>
                <div class="close">
                    <img
                        alt="x"
                        src="@/assets/x.svg"
                        @click="$store.commit('popups/hide', 'recipients')"
                    >
                </div>
            </div>
            <div class="body">
                <div class="inp-group">
                    <p class="group-title">Recipient 1</p>
                    <div class="inps-wrap">
                        <input
                            type="text"
                            class="inp"
                            name="recipient1name"
                            placeholder="Name"
                            required
                        >
                        <input
                            type="email"
                            class="inp"
                            name="recipient1email"
                            placeholder="Email"
                            required
                        >
                    </div>
                </div>
                <div class="inp-group">
                    <p class="group-title">Recipient 2</p>
                    <div class="inps-wrap">
                        <input
                            type="text"
                            class="inp"
                            name="recipient2name"
                            placeholder="Name"
                            required
                        >
                        <input
                            type="email"
                            class="inp"
                            name="recipient2email"
                            placeholder="Email"
                            required
                        >
                    </div>
                </div>
            </div>
            <div class="bottom">
                <button class="btn">save</button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    data: function() {
        return {}
    },
    methods: {
        async handleSubmit(e) {
            try {
                let { rid, key } = this.$route.params
                let data = {}

                ;[...e.currentTarget.elements].forEach(item => {
                    if(item.name)
                        data[item.name] = item.value
                })

                console.log(data)

                await fetch(`https://offers-dev.genstonerealty.com/api/saveFields.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        fid_83: data.recipient1name,
                        fid_84: data.recipient1email,
                        fid_88: data.recipient2name,
                        fid_89: data.recipient2email,
                        dbid: "brd8g7nm7",
                        rid,
                    })
                })

                this.$router.push(`/thank-you/${rid}`)
                this.$store.commit("popups/hide", "recipients")
            } catch (err) {
                console.error(err)
            }
        }
    },
}

</script>

<style lang="sass">

form.c-recipients-popup
    width: 520px
    background: #EFEFEF
    cursor: default

    div.head
        display: flex
        justify-content: flex-end
        align-items: center

    div.inp-group
        margin-bottom: 10px

        p.group-title
            font-size: 15px
            color: #000
            margin-bottom: 6px

        div.inps-wrap
            display: flex
            justify-content: space-between
            align-items: center
            
        input.inp
            width: 49%
            outline: none
            border: 2px solid #CCC
            padding: 12px 15px
            font-size: 16px

    div.bottom
        display: flex
        justify-content: center
        align-items: center

    button.btn
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        width: 230px
        height: 44px
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
        border-radius: 3px
        text-transform: uppercase
        font-weight: 600
        letter-spacing: 0.02em
        font-size: 14px
        line-height: 22px
        padding: 5px
        margin: 0 10px
        border: none
        outline: none
        background: #28A745
        color: #fff

</style>