<template>
    <div
        class="popup-wrap"
        @click="$store.commit('popups/hide', 'reject')"
    >
        <form class="c-reject-popup popup" @click.stop @submit.prevent="handleSubmit">
            <div class="head">
                <h2 class="title">Reject Reason</h2>
                <div class="close">
                    <img
                        alt="x"
                        src="@/assets/x.svg"
                        @click="$store.commit('popups/hide', 'reject')"
                    >
                </div>
            </div>
            <div class="body">
                <p class="descr">Describe the reason of the reject</p>
                <textarea class="note" name="message" required></textarea>
            </div>
            <div class="bottom">
                <div
                    class="btn cancel"
                    @click="$store.commit('popups/hide', 'reject')"
                >
                    <img src="@/assets/x.svg" alt="cancel">
                    <span>cancel</span>
                </div>
                <button class="btn reject">
                    <img src="@/assets/reject.svg" alt="reject" class="red">
                    <img src="@/assets/white-reject.svg" alt="reject" class="white">
                    <span>reject</span>
                </button>
                <div
                    class="btn offer"
                    @click="counterOfferClick"
                >
                    <img src="@/assets/green-edit.svg" alt="edit" class="green">
                    <img src="@/assets/white-edit.svg" alt="edit" class="white">
                    <span>counter-offer</span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    data: function() {
        return {}
    },
    methods: {
        counterOfferClick() {
            this.$store.commit("popups/hide", "reject")
            this.$store.commit("popups/show", "offer")
        },
        async handleSubmit(e) {
            try {
                let { rid } = this.$route.params
                let data = {}

                ;[...e.currentTarget.elements].forEach(item => {
                    if(item.name)
                        data[item.name] = item.value
                })

                let response = await fetch(`https://offers-dev.genstonerealty.com/api/saveFields.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        fid_59: "Rejected",
                        fid_61: data.message,
                        dbid: "brd8g7nm7",
                        rid,
                    })
                })

                response = await response.text()

                this.$store.commit("popups/hide", "reject")
                this.$router.push(`/thank-you/${rid}`)
            } catch (err) {
                console.error(err)
            }
        },
    },
}

</script>

<style lang="sass">

form.c-reject-popup
    background: #EFEFEF
    cursor: default

    h2.title
        color: #201648
        font-size: 24px
        line-height: 36px
        font-weight: 400

    textarea.note
        width: 100%
        resize: none
        height: 116px
        outline: none
        padding: 16px
        font-size: 16px
        line-height: 24px
        color: #222222
        border: none

    p.descr
        margin-bottom: 10px

    div.btn,
    button.btn
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
        border: none
        outline: none
        height: 44px
        width: 155px
        display: flex
        justify-content: center
        align-items: center
        text-transform: uppercase
        font-weight: 600
        font-size: 14px
        line-height: 22px
        margin: 0 4px
        padding: 5px
        letter-spacing: 0.02em
        cursor: pointer
        background: #fff
        border-radius: 3px
        transition: 0.1s

        img
            margin-right: 8px

        &.cancel
            color: #F77B00
        &.reject
            color: #DC3545
            img.white
                display: none
            &:hover
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #DC3545
                color: #fff

                img.white
                    display: block

                img.red
                    display: none
        &.offer
            color: #28A745
            img.white
                display: none
            &:hover
                background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #28A745
                color: #fff

                img.white
                    display: block

                img.green
                    display: none

@media screen and (max-width: 620px)
    form.c-reject-popup
        width: 95%

        div.bottom
            min-width: auto !important
            padding: 20px 5px !important

        div.btn
            width: auto

</style>