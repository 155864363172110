import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/start/:rid/:key',
    name: 'Start',
    component: () => import('../views/Start.vue')
  },
  {
    path: '/contract/:rid/:key/:position?',
    name: 'Contract',
    component: () => import('../views/Contract.vue')
  },
  {
    path: '/expired/:rid/:key',
    name: 'Expired',
    component: () => import('../views/Expired.vue')
  },
  {
    path: '/thank-you/:rid',
    name: 'Expired',
    component: () => import('../views/ThankYou.vue')
  },
  {
    path: '/sign-contract/:rid/:key',
    name: 'Expired',
    component: () => import('../views/SignContract.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
