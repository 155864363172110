<template>
    <div id="app">
        <Spinner v-if="!isDataLoaded" />
        <div
            class="main-content"
            v-if="isDataLoaded && !isExpiredOffer"
        >
            <router-view/>
            <RejectPopup v-if="popups.reject"/>
            <OfferPopup v-if="popups.offer"/>
            <RecipientsPopup v-if="popups.recipients"/>
        </div>
    </div>
</template>

<script>
import RejectPopup from "./popups/Reject"
import OfferPopup from "./popups/Offer"
import RecipientsPopup from "./popups/Recipients"
import Spinner from "./components/Spinner"

export default {
    data: function() {
        return {
            isExpiredOffer: false,
            isDataLoaded: false,
        }
    },
    components: {
        RecipientsPopup,
        RejectPopup,
        OfferPopup,
        Spinner,
    },
    async updated() {
        let { rid, key } = this.$route.params
        let isExpired = this.$store.state.isExpired
        let path = this.$router.currentRoute.path
        let isExpiredPage = path.split("/").includes("expired")

        if(isExpired && !isExpiredPage)
            this.$router.push(`/expired/${rid}/${key}`)
    },
    async beforeMount() {
        try {
            setTimeout(async () => {
                let { rid } = this.$route.params
    
                let response = await fetch(`https://offers-dev.genstonerealty.com/api/getFields.php?clist=71&rid=${rid}`)
    
                response = await response.json()

                console.log(response)

                this.isDataLoaded = true
                
                if(response.data[71].toLowerCase() === "yes")
                    this.$store.commit("setExpired")
            }, 500)
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        popups() {
            return this.$store.state.popups
        },
    },
}

</script>


<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap')

html,
body
    padding: 0
    margin: 0
    background: #EAEAEA
    *
        box-sizing: border-box
        font-family: "Poppins", Arial, sans-serif

p,
h1,
h2,
h3,
h4,
h5,
h6
    margin: 0
    padding: 0

div.container
    max-width: 1300px
    margin: 0 auto

header.head
    background: #fff

div.popup-wrap
    position: fixed
    top: 0
    left: 0
    background: rgba(0,0,0,.5)
    display: flex
    justify-content: center
    align-items: center
    height: 100vh
    width: 100%
    cursor: pointer
    z-index: 500

    div.head
        display: flex
        justify-content: space-between
        align-items: center
        padding: 14px 20px

    div.body
        padding: 20px 20px 30px 20px
    
    div.bottom
        display: flex
        justify-content: center
        align-items: center
        min-width: 520px
        padding: 20px 20px
        border-top: 1px solid rgba(0, 0, 0, 0.04)

    div.close
        cursor: pointer

</style>
