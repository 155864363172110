const popups = {
    namespaced: true,
    state: () => ({
        reject: false,
        offer: false,
        recipients: false,
        sign: false,
    }),
    mutations: {
        show(state, popupName) {
            state[popupName] = true
        },
        hide(state, popupName) {
            state[popupName] = false
        },
    },
    getters: {},
}

export default popups