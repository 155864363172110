<template>
    <div
        class="popup-wrap"
        @click="$store.commit('popups/hide', 'offer')"
    >
        <form class="c-offer-popup" @click.stop @submit.prevent="handleSubmit">
            <div class="head">
                <h2 class="title">Counteroffer</h2>
                <div class="close">
                    <img
                        alt="x"
                        src="@/assets/x.svg"
                        @click="$store.commit('popups/hide', 'offer')"
                    >
                </div>
            </div>
            <div class="body">
                <div class="inp-group">
                    <div class="inp-wrap">
                        <p class="inp-title">Initial Offer</p>
                        <span>$</span>
                        <input
                            type="text"
                            class="inp disabled"
                            disabled="true"
                            v-model="initialOffer"
                        >
                    </div>
                    <div class="inp-wrap">
                        <p class="inp-title">Counteroffer</p>
                        <span>$</span>
                        <input
                            type="text"
                            :class="`inp ${isRightPrice ? 'active' : 'inactive'}`"
                            name="counter"
                            v-model="counterOffer"
                            @keyup="handleChange"
                            required
                        >
                    </div>
                </div>
                <!-- <p class="error" v-if="!isRightPrice">{{ error }}</p> -->
                <p class="offer-text" v-if="isShowOfferText">
                    {{ offerText }}
                </p>
                <p class="descr">Comments</p>
                <textarea class="comment" placeholder="Type here..." name="comment"></textarea>
            </div>
            <div class="bottom">
                <div
                    class="btn cancel"
                    @click="$store.commit('popups/hide', 'offer')"
                >
                    <img src="@/assets/x.svg" alt="x">
                    <span>Cancel</span>
                </div>
                <button
                    v-show="!isShowConfirmButton"
                    class="btn continue"
                    @click="handleClick"
                >
                    <img src="@/assets/white-check.svg" alt="check">
                    <span>Сontinue</span>
                </button>
                <button
                    v-show="isShowConfirmButton"
                    @click="isSubmit = true"
                    class="btn confirm"
                >
                    <img src="@/assets/white-check.svg" alt="check">
                    <span>Confirm</span>
                </button>
            </div>
        </form>
    </div>
</template>

<script>

export default {
    data: function() {
        return {
            counterOffer: "",
            initialOffer: "",
            offerText: "",
            maxOffer: "",
            isShowOfferText: false,
            isShowConfirmButton: false,
            isSubmit: false,
        }
    },
    async mounted() {
        try {
            let { rid } = this.$route.params
            let response = await fetch(`https://offers-dev.genstonerealty.com/api/getFields.php?clist=55.57.58&rid=${rid}`)

            response = await response.json()

            this.initialOffer = Number(response.data[55]).toLocaleString()
            this.maxOffer = response.data[57]
            
        } catch (err) {
            console.error(err)
        }
    },
    computed: {
        isRightPrice() {
            if(!this.counterOffer.length)
                return false

            return +this.counterOffer.replaceAll(",", "") <= +this.maxOffer
        },
    },
    methods: {
        handleChange(e) {
            let value = e.currentTarget.value

            value = value.replaceAll(",", "")

            if(isNaN(+value[value.length-1]))
                this.counterOffer = Number(value.replace(/[A-z]/g, '')).toLocaleString()
            else
                this.counterOffer = Number(value).toLocaleString()

            this.isShowConfirmButton = false
        },
        async handleSubmit(e) {
            try {
                if(!this.isRightPrice || !this.isSubmit)
                    return false

                let { rid } = this.$route.params
                let data = {}

                ;[...e.currentTarget.elements].forEach(item => {
                    if(item.name)
                        data[item.name] = item.value
                })

                let response = await fetch(`https://offers-dev.genstonerealty.com/api/saveFields.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        fid_56: data.counter.replaceAll(",", ""),
                        fid_81: data.comment,
                        fid_59: "Accepted",
                        dbid: "brd8g7nm7",
                        rid,
                    })
                })

                response = await response.text()

                this.$store.commit("popups/hide", "offer")
            } catch (err) {
                console.error(err)
            }
        },
        async handleClick() {
            try {
                let { rid } = this.$route.params
    
                await fetch(`https://offers-dev.genstonerealty.com/api/saveFields.php`, {
                    method: "POST",
                    body: JSON.stringify({
                        fid_56: this.counterOffer,
                        dbid: "brd8g7nm7",
                        rid,
                    })
                })
    
                let response = await fetch(`https://offers-dev.genstonerealty.com/api/getFields.php?clist=58&rid=${rid}`)

                response = await response.json()

                this.offerText = response.data[58]
                this.isShowOfferText = true

                if(this.isRightPrice)
                    this.isShowConfirmButton = true

            } catch (err) {
                console.error(err)
            }
        },
    },
}

</script>

<style lang="sass">

form.c-offer-popup
    width: 520px
    background: #EFEFEF
    cursor: default

    div.btn,
    button.btn
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        width: 230px
        height: 44px
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.12)
        border-radius: 3px
        text-transform: uppercase
        font-weight: 600
        letter-spacing: 0.02em
        font-size: 14px
        line-height: 22px
        padding: 5px
        margin: 0 10px
        border: none
        outline: none

        &.cancel
            background: #fff
            color: #F77B00
        &.confirm
            background: #28A745
            color: #fff
        &.continueasfasfasfsafasf
            background: orange
            color: #fff
        
        img
            max-width: 10px
            margin-right: 8px

    div.inp-group
        display: flex
        justify-content: space-between
        margin-bottom: 20px

    div.inp-wrap
        width: 49%
        position: relative

        p.inp-title
            font-weight: 500
            font-size: 14px
            line-height: 21px
            letter-spacing: 0.02em
            color: #555555
            margin-bottom: 3px

        input.inp
            width: 100%
            height: 52px
            outline: none
            font-size: 16px
            line-height: 24px
            padding: 15px 12px 12px 30px
            font-size: 16px
            line-height: 24px

            &.disabled
                background: rgba(0,0,0,0)
                border: 2px solid #CCC

            &.active
                border: 4px solid #C0D3DA

            &.inactive
                border: 4px solid #FFCCCB

        span
            position: absolute
            left: 14px
            bottom: 12px
            font-size: 16px
            line-height: 24px
            color: #888888

    p.descr
        margin-bottom: 3px
        font-weight: 500
        font-size: 14px
        line-height: 21px
        letter-spacing: 0.02em
        color: #555555

    textarea.comment
        width: 100% !important
        height: 116px !important
        padding: 16px
        font-size: 16px
        line-height: 24px
        outline: none
        resize: none
        border: nonemax offer 350,00

    p.offer-text
        margin-bottom: 10px

    p.error
        font-size: 15px
        color: #000

@media screen and (max-width: 620px)
    form.c-offer-popup
        width: 95%

        div.bottom
            min-width: auto !important
            padding: 20px 5px !important

        div.btn
            width: 40%

</style>
